import React from 'react';

import {formerCitiesRedirect} from 'core/redirects';

import fetchMonthNameData from 'actions/monthName';
import {
  setRuntimeVariable,
  setRuntimeVariables,
} from 'actions/runtime';
import {
  current as fetchCurrentGeoData,
  autodetect as fetchAutodetectGeoData,
} from 'actions/geo';
import {fetchResortCitiesList} from 'actions/weatherCities';

async function action({store, path}, {v: cityPrefix, city, location: cityLocation, monthName}) {
  const {
    redirectCondition,
    redirectObj,
  } = formerCitiesRedirect(cityPrefix, city, cityLocation, monthName);

  if (redirectCondition) return redirectObj;

  const geoid = +store.getState().runtime.geoid || null;
  const location = cityLocation ? `/${encodeURIComponent(cityLocation)}` : '';
  const urlPath = `${cityPrefix}-${encodeURIComponent(city)}${location}`;
  const isMobile = store.getState().runtime.isMobile || false;

  store.dispatch(setRuntimeVariables({
    page:                  'monthly',
    pagePrefix:            cityPrefix,
    weatherText:           'WEATHER_MONTHLY',
    weatherAdditionalText: 'WEATHER_MONTHLY_CLIMATE',
    isNewDesign:           false,
    cityPeriod:            monthName,
  }));

  const workers = [
    store.dispatch(fetchMonthNameData(geoid, urlPath, monthName)),
    store.dispatch(fetchResortCitiesList(monthName)),
    store.dispatch(fetchAutodetectGeoData()),
    store.dispatch(fetchCurrentGeoData()),
  ];

  await Promise.all(workers);

  if (__CLIENT__) {
    store.dispatch(setRuntimeVariable({
      name:  'pageIsLoaded',
      value: true,
    }));
  }

  let Layout;
  let MonthName;

  /* eslint-disable global-require */
  if (__DESKTOP__ && !isMobile) {
    Layout = require('Desktop/components/Layout').default;
    MonthName = require('Desktop/pages/MonthName').default;
  } else if (__MOBILE__ && isMobile) {
    Layout = require('Mobile/components/Layout').default;
    MonthName = require('Mobile/pages/MonthName').default;
  }
  /* eslint-enable global-require */

  const newState = store.getState();
  const meta = newState.seo;
  // eslint-disable-next-line prefer-destructuring
  const error = newState.monthName.error;

  if (!newState.monthName.isDataFetched) {
    return {
      status:   301,
      redirect: `/${urlPath}/30-days/`,
    };
  } else if (error) {
    return {
      status:   error.status || 500,
      redirect: true,
    };
  }

  return {
    meta,
    component: (
      <Layout routePage={path}>
        <MonthName />
      </Layout>
    ),
  };
}

export default {
  path: '/:monthName(january|february|march|april|may|june|july|august|september|october|november|december)',
  action,
};

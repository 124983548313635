export const formerCities = {
  ukraina:             ['severske', 'krasnom-luche', 'shakhterske', 'limane', 'shakhterske', 'uglegorske', 'kirovske', 'pervomayske'],
  'donetskaya-oblast': ['yalte'],
};

export const formerRegions = {
  yalte: {
    from: 'donetskaya-oblast',
    to:   'donetskaya-narodnaya-respublika',
  },
};

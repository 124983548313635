import React from 'react';

import {formerCitiesRedirect} from 'core/redirects';

import {
  setRuntimeVariables,
  setRuntimeVariable,
} from 'actions/runtime';

import fetchAgroprognozData from 'actions/agroprognoz';

async function actionHome({store, path}, {v: cityPrefix, city, location: cityLocation}) {
  const {
    redirectCondition,
    redirectObj,
  } = formerCitiesRedirect(cityPrefix, city, cityLocation, 'agroprognoz');

  if (redirectCondition) return redirectObj;

  const geoid = +store.getState().runtime.geoid || null;
  const location = cityLocation ? `/${encodeURIComponent(cityLocation)}` : '';
  const urlPath = `${cityPrefix}-${encodeURIComponent(city)}${location}`;
  const isMobile = store.getState().runtime.isMobile || false;

  store.dispatch(setRuntimeVariables({
    page:        'agroprognoz',
    pagePrefix:  cityPrefix,
    isNewDesign: false,
    cityPeriod:  'agroprognoz',
  }));


  let Layout;
  let Agroprognoz;
  let StaticText;

  /* eslint-disable global-require */
  if (__DESKTOP__ && !isMobile) {
    Layout = require('Desktop/components/Layout').default;
    Agroprognoz = require('Desktop/pages/Agroprognoz').default;
    StaticText = require('Desktop/components/StaticText').default;
  } else if (__MOBILE__ && isMobile) {
    Layout = require('Mobile/components/Layout').default;
    Agroprognoz = require('Mobile/pages/Agroprognoz').default;
  }
  /* eslint-enable global-require */

  await store.dispatch(fetchAgroprognozData(geoid, urlPath));

  const newState = store.getState();
  const meta = newState.seo;
  // eslint-disable-next-line prefer-destructuring
  const error = newState.geomagnetic.error;

  if (error) {
    return {
      status:   error.status || 500,
      redirect: true,
    };
  }

  if (__CLIENT__) {
    store.dispatch(setRuntimeVariable({
      name:  'pageIsLoaded',
      value: true,
    }));
  }

  return ({
    meta,
    component: (
      <Layout routePage={path} routePrefix={cityPrefix}>
        <Agroprognoz />
        {StaticText && <StaticText text={meta.seo_text} />}
      </Layout>
    ),
  });
}

export default {
  path:   '/agroprognoz',
  action: actionHome,
};

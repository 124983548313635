import React from 'react';
import * as pt from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import _omit from 'lodash.omit';

import Cookies from 'universal-cookie';

import RamblerFooter from '@rambler-components/footer';
import RamblerFooterStyle from '@rambler-components/footer/styles.css';

import {menuLinks, socialLinks} from 'static/footer.json';
import YandexIcon from './i/logo-yandex.svg';
import s from './index.scss';

class Footer extends React.Component {
  static propTypes = {
    page: pt.string.isRequired,
    env:  pt.string.isRequired,
  };

  clickMenu = (e, linkData) => {
    if (linkData.isSwitcher) {
      e.preventDefault();

      const cookies = new Cookies();
      const {env} = this.props;

      if (env === 'stage' || env === 'mxx') {
        // 2 т.к. и для мануфактуры и для стейджа
        cookies.set('rswitch', 'mobile', {
          path:   '/',
          domain: '.rambler.space',
        });
        cookies.set('rswitch', 'mobile', {
          path:   '/',
          domain: '.rambler.ru',
        });
      } else {
        cookies.set('rswitch', 'mobile', {
          path:   '/',
          domain: '.rambler.ru',
        });
      }

      window.location.reload();
    }
  }

  render() {
    const {
      page,
    } = this.props;

    return (
      <RamblerFooter
        menuLinks={menuLinks.map(link => ({
          ..._omit(link, ['top100', 'isSwitcher']),
          onClick:        e => this.clickMenu(e, link),
          'data-weather': `${page}::footer::${link.top100}`,
        }))}
        socialLinks={socialLinks.map(link => ({
          ..._omit(link, ['top100']),
          'data-weather': `${page}::footer::smm::${link.top100}`,
        }))}
        partnerElement={
          <a
            href="https://yandex.ru/pogoda"
            target="_blank"
            className={s.yandexLink}
            data-weather={`${page}::footer::yandex_link`}
            rel="nofollow sponsored"
          >
            <YandexIcon />
          </a>
        }
      />
    );
  }
}

export const FooterWithoutHocs = Footer;
export default withStyles(s, RamblerFooterStyle)(Footer);

import {
  FETCH_MONTH_DATA_SUCCESS,
  FETCH_MONTH_DATA_FAILURE,
} from 'constants/actions/month';

import {FETCH_TOWN_DATA_SUCCESS} from 'constants/actions/town';
import {FETCH_NEAREST_CITIES_DATA_SUCCESS} from 'constants/actions/cities';
import FETCH_SEO_DATA_SUCCESS from 'constants/actions/seo';
import FETCH_BREADCRUMBS_DATA_SUCCESS from 'constants/actions/breadcrumbs';

import {
  DEFAULT_API_URL,
  MONTH_API_URLS,
} from 'constants/index';

import captureException from 'core/captureException';
import {dnrApiUrlTransformation} from 'core/redirects';

export default function fetchMonthData(geoID, urlPath) {
  return async(dispatch, getState, {fetch, statsd}) => {
    let startTime = new Date();

    try {
      let url = `${DEFAULT_API_URL}${MONTH_API_URLS.home}`;

      if (geoID && !urlPath) {
        url += `?geo_id=${geoID}`;
      } else if ((geoID && urlPath) || (!geoID && urlPath)) {
        url += `?url_path=${urlPath}`;
      }

      url = dnrApiUrlTransformation(url);

      const req = await fetch(url);
      if (req.ok === false) {
        const err = new Error(`${new Date()} Не удалось получить данные о погоде на Месяц: ${url}`);

        err.status = req.status;
        throw err;
      }

      if (__SERVER__) {
        statsd.timing('api_request.fetchMonthData.timer', startTime);
        statsd.timing('api_request.fetchGlobalTimer.timer', startTime);
      }

      startTime = new Date();
      const data = await req.json();
      if (__SERVER__) {
        statsd.timing('json.fetchMonthData.timer', startTime);
      }

      await dispatch({
        type:    FETCH_MONTH_DATA_SUCCESS,
        payload: {data},
      });
      await dispatch({
        type:    FETCH_TOWN_DATA_SUCCESS,
        payload: {town: data.town},
      });
      await dispatch({
        type:    FETCH_NEAREST_CITIES_DATA_SUCCESS,
        payload: {cities: data.cities_nearby},
      });
      await dispatch({
        type:    FETCH_SEO_DATA_SUCCESS,
        payload: {seo: data.seo},
      });
      await dispatch({
        type:    FETCH_BREADCRUMBS_DATA_SUCCESS,
        payload: {breadcrumbs: data.breadcrumbs, isWithMicrodata: true},
      });
    } catch (error) {
      captureException(error);

      if (__SERVER__) {
        statsd.increment('service_error.fetchMonthData.counter');
      }

      await dispatch({
        type:    FETCH_MONTH_DATA_FAILURE,
        payload: {
          stack:   error.stack,
          message: error.message,
          status:  error.status || 500,
        },
      });
      console.error(`${new Date()} ${error}`);
    }
  };
}

import {
  FETCH_DAY_DATA_SUCCESS,
  FETCH_DAY_DATA_FAILURE,
} from 'constants/actions/day';

import {FETCH_TOWN_DATA_SUCCESS} from 'constants/actions/town';
import {FETCH_NEAREST_CITIES_DATA_SUCCESS} from 'constants/actions/cities';
import FETCH_SEO_DATA_SUCCESS from 'constants/actions/seo';
import FETCH_BREADCRUMBS_DATA_SUCCESS from 'constants/actions/breadcrumbs';
import {SET_RUNTIME_VARIABLES} from 'constants/redux/index';

import {dnrApiUrlTransformation} from 'core/redirects';

import {
  DEFAULT_API_URL,
  DAY_URLS,
} from 'constants/index';

import captureException from 'core/captureException';

export default function fetchDayData(geoID, type, urlPath, date, allData = 0) {
  return async(dispatch, getState, {fetch, statsd}) => {
    let startTime = new Date();

    try {
      let url = `${DEFAULT_API_URL}${DAY_URLS[type]}?all_data=${allData}`;

      if (geoID && !urlPath) {
        url += `&geo_id=${geoID}`;
      } else if ((geoID && urlPath) || (!geoID && urlPath)) {
        url += `&url_path=${urlPath}`;
      }

      if (type === 'day') {
        url += `&date=${date}`;
      }

      url = dnrApiUrlTransformation(url);

      const req = await fetch(url);

      if (req.ok === false) {
        const err = new Error(`${new Date()} Не удалось получить данные о погоде на ${type}: ${url}`);

        err.status = req.status;
        throw err;
      }
      if (__SERVER__) {
        statsd.timing('api_request.fetchDayData.timer', startTime);
        statsd.timing('api_request.fetchGlobalTimer.timer', startTime);
      }

      startTime = new Date();
      const data = await req.json();
      if (__SERVER__) {
        statsd.timing('json.fetchDayData.timer', startTime);
      }

      await dispatch({
        type:    FETCH_DAY_DATA_SUCCESS,
        payload: {data},
      });
      await dispatch({
        type:    FETCH_TOWN_DATA_SUCCESS,
        payload: {town: data.town},
      });
      await dispatch({
        type:    FETCH_NEAREST_CITIES_DATA_SUCCESS,
        payload: {cities: data.cities_nearby},
      });
      await dispatch({
        type:    FETCH_SEO_DATA_SUCCESS,
        payload: {seo: data.seo},
      });
      await dispatch({
        type:    FETCH_BREADCRUMBS_DATA_SUCCESS,
        payload: {breadcrumbs: data.breadcrumbs, isWithMicrodata: true},
      });
      await dispatch({
        type:    SET_RUNTIME_VARIABLES,
        payload: {
          weatherTemp: data.date_weather.temperature.toString(),
          weatherIcon: data.date_weather.icon,
          warning:     data.date_weather.alert_text_short || '',
        },
      });
    } catch (error) {
      captureException(error);

      if (__SERVER__) {
        statsd.increment('service_error.fetchDayData.counter');
      }

      await dispatch({
        type:    FETCH_DAY_DATA_FAILURE,
        payload: {
          stack:   error.stack,
          message: error.message,
          status:  error.status || 500,
        },
      });
      console.error(`${new Date()} ${error}`);
    }
  };
}

import React from 'react';

import _isNaN from 'lodash.isnan';

import {formerCitiesRedirect} from 'core/redirects';

import {
  setRuntimeVariable,
  setRuntimeVariables,
} from 'actions/runtime';
import fetchDayData from 'actions/day';
import {
  current as fetchCurrentGeoData,
  autodetect as fetchAutodetectGeoData,
} from 'actions/geo';

import {MONTHS_ENG} from 'constants/months';

async function actionToday({store, path}, {v: cityPrefix, city, location: cityLocation}) {
  const {redirectCondition, redirectObj} = formerCitiesRedirect(cityPrefix, city, cityLocation, 'today');

  if (redirectCondition) return redirectObj;

  const geoid = +store.getState().runtime.geoid || null;
  const location = cityLocation ? `/${encodeURIComponent(cityLocation)}` : '';
  const urlPath = `${cityPrefix}-${encodeURIComponent(city)}${location}`;
  const isMobile = store.getState().runtime.isMobile || false;
  const isNeedFullData = isMobile ? 1 : 0;

  store.dispatch(setRuntimeVariables({
    page:                  'today',
    pagePrefix:            cityPrefix,
    weatherText:           '',
    weatherAdditionalText: '',
    isNewDesign:           false,
    cityPeriod:            'today',
  }));

  const workers = [
    store.dispatch(fetchDayData(geoid, 'today', urlPath, null, isNeedFullData)),
    store.dispatch(fetchAutodetectGeoData()),
    store.dispatch(fetchCurrentGeoData()),
  ];

  await Promise.all(workers);

  if (__CLIENT__) {
    store.dispatch(setRuntimeVariable({
      name:  'pageIsLoaded',
      value: true,
    }));
  }

  let Layout;
  let Day;
  let StaticText;

  /* eslint-disable global-require */
  if (__DESKTOP__ && !isMobile) {
    Layout = require('Desktop/components/Layout').default;
    Day = require('Desktop/pages/Day').default;
    StaticText = require('Desktop/components/StaticText').default;
  } else if (__MOBILE__ && isMobile) {
    Layout = require('Mobile/components/Layout').default;
    Day = require('Mobile/pages/Day').default;
  }
  /* eslint-enable global-require */

  const newState = store.getState();
  const meta = newState.seo;
  // eslint-disable-next-line prefer-destructuring
  const error = newState.day.error;

  if (error) {
    return {
      status:   error.status || 500,
      redirect: true,
    };
  }

  return {
    meta,
    component: (
      <Layout routePage={path} routePrefix={cityPrefix}>
        <Day />
        {StaticText && <StaticText text={meta.seoText} />}
      </Layout>
    ),
  };
}

async function actionTomorrow({store, path}, {v: cityPrefix, city, location: cityLocation}) {
  const {redirectCondition, redirectObj} = formerCitiesRedirect(cityPrefix, city, cityLocation, 'tomorrow');

  if (redirectCondition) return redirectObj;

  const location = cityLocation ? `/${encodeURIComponent(cityLocation)}` : '';
  const urlPath = `${cityPrefix}-${encodeURIComponent(city)}${location}`;
  const geoid = +store.getState().runtime.geoid || null;
  const isMobile = store.getState().runtime.isMobile || false;
  const isNeedFullData = isMobile ? 1 : 0;

  store.dispatch(setRuntimeVariables({
    page:                  'tomorrow',
    pagePrefix:            cityPrefix,
    weatherText:           '',
    weatherAdditionalText: '',
    isNewDesign:           false,
    cityPeriod:            'tomorrow',
  }));

  const workers = [
    store.dispatch(fetchDayData(geoid, 'tomorrow', urlPath, null, isNeedFullData)),
  ];

  await Promise.all(workers);

  if (__CLIENT__) {
    store.dispatch(setRuntimeVariable({
      name:  'pageIsLoaded',
      value: true,
    }));
  }

  let Layout;
  let Day;
  let StaticText;

  /* eslint-disable global-require */
  if (__DESKTOP__ && !isMobile) {
    Layout = require('Desktop/components/Layout').default;
    Day = require('Desktop/pages/Day').default;
    StaticText = require('Desktop/components/StaticText').default;
  } else if (__MOBILE__ && isMobile) {
    Layout = require('Mobile/components/Layout').default;
    Day = require('Mobile/pages/Day').default;
  }
  /* eslint-enable global-require */

  const newState = store.getState();
  const meta = newState.seo;
  // eslint-disable-next-line prefer-destructuring
  const error = newState.day.error;

  if (error) {
    return {
      status:   error.status || 500,
      redirect: true,
    };
  }

  return {
    meta,
    component: (
      <Layout routePage={path} routePrefix={cityPrefix}>
        <Day />
        {StaticText && <StaticText text={meta.seoText} />}
      </Layout>
    ),
  };
}

async function actionDay(
  {store, path},
  {v: cityPrefix, city, location: cityLocation, date, month},
) {
  if (_isNaN(+date) || !MONTHS_ENG.includes(month)) {
    return null;
  }

  const period = `${parseInt(date, 10)}-${month}`;

  const {redirectCondition, redirectObj} = formerCitiesRedirect(cityPrefix, city, cityLocation, `${period}`);

  if (redirectCondition) return redirectObj;

  const geoid = +store.getState().runtime.geoid || null;
  const location = cityLocation ? `/${encodeURIComponent(cityLocation)}` : '';
  const urlPath = `${cityPrefix}-${encodeURIComponent(city)}${location}`;

  if (date.indexOf('0') === 0) {
    return {
      status:   301,
      redirect: `/${urlPath}/${period}/`,
    };
  }

  const isMobile = store.getState().runtime.isMobile || false;
  const isNeedFullData = isMobile ? 1 : 0;

  store.dispatch(setRuntimeVariables({
    page:                  'day',
    pagePrefix:            cityPrefix,
    weatherText:           '',
    weatherAdditionalText: '',
    isNewDesign:           false,
    cityPeriod:            period,
  }));

  const workers = [
    store.dispatch(fetchDayData(geoid, 'day', urlPath, period, isNeedFullData)),
  ];

  await Promise.all(workers);

  if (__CLIENT__) {
    store.dispatch(setRuntimeVariable({
      name:  'pageIsLoaded',
      value: true,
    }));
  }

  let Layout;
  let Day;
  let StaticText;

  /* eslint-disable global-require */
  if (__DESKTOP__ && !isMobile) {
    Layout = require('Desktop/components/Layout').default;
    Day = require('Desktop/pages/Day').default;
    StaticText = require('Desktop/components/StaticText').default;
  } else if (__MOBILE__ && isMobile) {
    Layout = require('Mobile/components/Layout').default;
    Day = require('Mobile/pages/Day').default;
  }
  /* eslint-enable global-require */

  const newState = store.getState();
  const meta = newState.seo;
  // eslint-disable-next-line prefer-destructuring
  const error = newState.day.error;

  if (error) {
    return {
      status:   error.status || 500,
      redirect: true,
    };
  }

  return {
    meta,
    component: (
      <Layout routePage={path} routePrefix={cityPrefix}>
        <Day />
        {StaticText && <StaticText text={meta.seoText} />}
      </Layout>
    ),
  };
}

export default {
  path:     '',
  children: [
    {
      path:   '/today',
      action: actionToday,
    },
    {
      path:   '/tomorrow',
      action: actionTomorrow,
    },
    {
      path:   '/:date-:month',
      action: actionDay,
    },
  ],
};

import React from 'react';

import {formerCitiesRedirect} from 'core/redirects';

import {
  setRuntimeVariable,
  setRuntimeVariables,
} from 'actions/runtime';
import fetchGeomagneticData from 'actions/geomagnetic';
import fetchNowData from 'actions/now';
import {
  current as fetchCurrentGeoData,
  autodetect as fetchAutodetectGeoData,
} from 'actions/geo';

async function actionGeomagnetic({store, path}, {v: cityPrefix, city, location: cityLocation}) {
  const {
    redirectCondition,
    redirectObj,
  } = formerCitiesRedirect(cityPrefix, city, cityLocation, 'geomagnetic');

  if (redirectCondition) return redirectObj;

  const geoid = +store.getState().runtime.geoid || null;
  const location = cityLocation ? `/${encodeURIComponent(cityLocation)}` : '';
  const urlPath = `${cityPrefix}-${encodeURIComponent(city)}${location}`;
  const isMobile = store.getState().runtime.isMobile || false;

  store.dispatch(setRuntimeVariables({
    page:                  'geomagnetic',
    pagePrefix:            cityPrefix,
    weatherText:           'RWEATHER_GEOMAGNETIC',
    weatherAdditionalText: 'RWEATHER_GEOMAGNETIC_MAIN',
    isNewDesign:           false,
    cityPeriod:            'geomagnetic',
  }));

  const workers = [
    store.dispatch(fetchGeomagneticData(geoid, 'geomagnetic', urlPath)),
    store.dispatch(fetchNowData(geoid, urlPath, true)),
    store.dispatch(fetchAutodetectGeoData()),
    store.dispatch(fetchCurrentGeoData()),
  ];

  await Promise.all(workers);

  if (__CLIENT__) {
    store.dispatch(setRuntimeVariable({
      name:  'pageIsLoaded',
      value: true,
    }));
  }

  let Layout;
  let Geomagnetic;

  /* eslint-disable global-require */
  if (__DESKTOP__ && !isMobile) {
    Layout = require('Desktop/components/Layout').default;
    Geomagnetic = require('Desktop/pages/Geomagnetic').default;
  } else if (__MOBILE__ && isMobile) {
    Layout = require('Mobile/components/Layout').default;
    Geomagnetic = require('Mobile/pages/Geomagnetic').default;
  }
  /* eslint-enable global-require */

  const newState = store.getState();
  const meta = newState.seo;
  // eslint-disable-next-line prefer-destructuring
  const error = newState.geomagnetic.error;

  if (error) {
    return {
      status:   error.status || 500,
      redirect: true,
    };
  }

  return {
    meta,
    component: (
      <Layout routePage={path} routePrefix={cityPrefix}>
        <Geomagnetic />
      </Layout>
    ),
  };
}

export default {
  path:   '/geomagnetic',
  action: actionGeomagnetic,
};

/**
 * Принимает модуль сентри для клиента или сервера и возвращает функцию для отправки
 * ошибки на сервер сентри
 *
 * @param Sentry модуль сентри для клиента или сервера
 */

// eslint-disable-next-line import/prefer-default-export
export const sendToSentry = (Sentry) => (
  err,
  extraTags,
  modifyScope,
) => {
  Sentry.withScope((scope) => {
    if (extraTags) {
      const tagEntries = Object.entries(extraTags);
      tagEntries.forEach(([tagKey, tagValue]) => {
        scope.setTag(tagKey, tagValue);
      });
    }
    if (modifyScope) {
      modifyScope(scope);
    }
    Sentry.captureException(err);
  });
};

/* eslint-disable import/prefer-default-export */

import {
  SET_RUNTIME_VARIABLE,
  SET_RUNTIME_VARIABLES,
  INCREMENT_RUNTIME_VARIABLE,
} from 'constants/redux/index';

export function setRuntimeVariable({name, value}) {
  return {
    type:    SET_RUNTIME_VARIABLE,
    payload: {
      name,
      value,
    },
  };
}

export function setRuntimeVariables(variables) {
  return {
    type:    SET_RUNTIME_VARIABLES,
    payload: variables,
  };
}

export function incrementRuntimeVariable(name) {
  return {
    type:    INCREMENT_RUNTIME_VARIABLE,
    payload: {
      name,
    },
  };
}

import {formerCities, formerRegions} from 'constants/redirects';

const formerCitiesRedirect = (cityPrefix, city, cityLocation, section = undefined) => {
  if (typeof formerRegions[city] !== 'undefined' && formerRegions[city].from === cityLocation) {
    return {
      redirectCondition: true,
      redirectObj:       {
        status:   301,
        redirect: `/${cityPrefix}-${encodeURIComponent(city)}/${encodeURIComponent(formerRegions[city].to)}/${section ? `${section}/` : ''}`,
      }};
  }

  if (formerCities[cityLocation]?.includes(city)) {
    return {
      redirectCondition: true,
      redirectObj:       {
        status:   301,
        redirect: `/${cityPrefix}-${encodeURIComponent(city)}/${section ? `${section}/` : ''}`,
      }};
  }

  return {
    redirectCondition: false,
  };
};

const dnrApiUrlTransformation = (url) => {
  if (url.includes('donetskaya-nardnaya-respublika')) {
    return url.replace('donetskaya-nardnaya-respublika', 'donetskaya-narodnaya-respublika');
  }

  return url;
};

export {formerCitiesRedirect, dnrApiUrlTransformation};

/* eslint-disable import/prefer-default-export */

/* https://confluence.rambler-co.ru/pages/viewpage.action?pageId=37339005
   До вызова скриптов загрузки или перезагрузки всех рекламных мест,
   необходимо вызывать скрипт удаления баннера брендирования
 */

export const brandingCleaner = () => {
  if (typeof window['DSP-Branding'] !== 'undefined') {
    const banners = Object.keys(window['DSP-Branding'].banners);

    if (banners.length > 0) {
      banners.forEach(key => {
        window['DSP-Branding'].banners[key].removeBanner();
        document.body.classList.remove('is-branding');
      });
    }
  }
};

/**
 * Безопасное получение свойства объекта, возвращает fallback при ошибке
 *
 * @param getPropFn - Функция возращающая свойство объекта
 * @param fallback - Вернется если произошла ошибка при получении свойства
 */

// eslint-disable-next-line arrow-parens, import/prefer-default-export
export const safeGet = (getPropFn, fallback) => {
  try {
    const result = getPropFn();

    return result || fallback;
  } catch (e) {
    return fallback;
  }
};

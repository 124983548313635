/* eslint-disable import/prefer-default-export */
import {useState, useEffect} from 'react';
import ResizeObserver from 'resize-observer-polyfill';

/** Хук для отслеживания рендера рекламы */
export const useAdvObserver = (adRef) => {
  const [isBannerLoaded, setIsBannerLoaded] = useState(false);

  const onResize = () => {
    if (adRef && adRef.current) {
      setIsBannerLoaded(adRef.current.getBoundingClientRect().height > 10);
    }
  };

  useEffect(() => {
    if (adRef && adRef.current && !isBannerLoaded) {
      let resizeObserver = new ResizeObserver(onResize);

      resizeObserver.observe(adRef.current);
      onResize();

      return () => {
        if (resizeObserver) {
          if (adRef.current) {
            resizeObserver.unobserve(adRef.current);
          } else {
            resizeObserver.disconnect();
          }

          resizeObserver = null;
        }
      };
    }

    return undefined;
  }, [adRef]);

  return isBannerLoaded;
};
